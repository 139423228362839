import { useRouter } from "next/router";
import { GetStaticPropsResult } from "next";

import { Form, IFormProps } from "src/components/Form";
import { EInputType } from "src/components/Form/FormSection/FormGroup/FormRow/FormField";
import { validationBuilder } from "src/components/Form/validation/validationBuilder";
import { EIcon } from "src/components/Icon";
import { EMainLayout } from "src/components/Layout/Page/EMainLayout";

interface IFormFields {
  portal: string;
}

interface IPortalLinksItem {
  value: string;
  label: string;
  icon: EIcon;
}

const portalLinksArray: IPortalLinksItem[] = [
  {
    value: "https://gateway.tillpayments.com/en/login",
    label: "Gateway",
    icon: EIcon.TILL_VISION_LOGIN,
  },
  {
    value: "https://merchant.tillpayments.com/",
    label: "Merchant Services",
    icon: EIcon.SHOPPING_BAG,
  },
  {
    value: "https://paybylink.tillpayments.com/",
    label: "Pay By Link",
    icon: EIcon.PBL_LOGIN,
  },
];

export default function Register(): JSX.Element {
  const router = useRouter();

  const formConfig: IFormProps<IFormFields>["formConfig"] = [
    {
      groups: [
        {
          rows: [
            {
              fields: [
                {
                  id: "portal",
                  label: "Portal Name",
                  type: EInputType.SELECT,
                  data: portalLinksArray,
                  placeholder: "Select a portal",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const initialValues: IFormProps<IFormFields>["initialValues"] = {
    portal: "",
  };

  const validate: IFormProps<IFormFields>["validate"] = validationBuilder({
    portal: {
      type: EInputType.SELECT,
      required: true,
    },
  });

  async function handleSubmit(values: IFormFields) {
    await router.push(values.portal);
  }

  return (
    <Form<IFormFields>
      formConfig={formConfig}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      submitLabel="Continue"
    />
  );
}

interface ISigninStaticProps {
  title: string;
  mainLayout: EMainLayout;
}

export async function getStaticProps(): Promise<
  GetStaticPropsResult<ISigninStaticProps>
> {
  return {
    props: {
      title: "Select Portal to Login",
      mainLayout: EMainLayout.Public,
    },
  };
}
